<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" class="main" width="650">
        <v-card class="main" max-width="650">
          <v-card-title class="carTitle">
            <v-row>
              <v-alert v-show="alert.visible" type="success">{{
                alert.text
              }}</v-alert>
              <v-col>
                <span>Mi carrito</span>
                <v-icon color="white">shopping_cart</v-icon>
              </v-col>
              <v-col class="totalPrice">
                <span>Total: RD$ {{ totalAmount }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text
            >Articulos en el carrito: {{ getCartByUser.length }}</v-card-text
          >

          <h1 class="message" v-if="getCartByUser.length === 0">
            No hay articulos en el carrito
          </h1>
          <v-row>
            <p class="listStyle">
              <v-row v-for="(item, index) in getCartByUser" :key="index">
                <v-col cols="12" md="3">
                  <v-img
                    class="center"
                    :src="item.img"
                    :lazy-src="item.img"
                  ></v-img>
                </v-col>
                <v-col>
                  <div>
                    <div class="vertical-center ml-3 mt-5 pt-4">
                      <v-row class="itemTitle">
                        {{ item.name }}
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-row align="center" justify="center" class="row1"
                            >Categoria</v-row
                          >
                          <v-row align="center" justify="center" class="row2">{{
                            item.subCategory
                          }}</v-row>
                        </v-col>
                        <v-col>
                          <v-row align="center" justify="center" class="row1"
                            >Cantidad</v-row
                          >
                          <v-row align="center" justify="center" class="row2">{{
                            item.quantity
                          }}</v-row>
                        </v-col>
                        <v-col>
                          <v-row align="center" justify="center" class="row1"
                            >Talla</v-row
                          >
                          <v-row align="center" justify="center" class="row2">{{
                            item.currentSize
                          }}</v-row>
                        </v-col>
                        <v-col>
                          <v-row align="center" justify="center" class="row1"
                            >Precio/U</v-row
                          >
                          <v-row align="center" justify="center" class="row2"
                            >RD${{ item.price }}</v-row
                          >
                        </v-col>
                        <v-col class="mt-2">
                          <v-row align="center" justify="space-around">
                            <button
                              @click="remove(item.id)"
                              class="buttonStyle"
                            >
                              Remover
                            </button>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
                <v-row>
                  <v-divider class="mt-2"></v-divider>
                </v-row>
              </v-row>
            </p>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" lg="8" md="8" sm="8">
              <textarea
                v-model="text"
                placeholder="Escriba una nota"
                class="form-control textArea"
                id="exampleFormControlTextarea1"
                maxlength="500"
                rows="3"
              ></textarea>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-btn
              :loading="loading"
              rounded
              color="warning"
              @click="sendRequest"
              class="text-center center"
              dark
            >
              Realizar pedido
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as emailjs from "emailjs-com";
export default {
  data() {
    return {
      text: "",
      dialog: false,
      loading: false,
      alert: {
        visible: false,
        text: "",
      },
    };
  },
  created() {
    emailjs.init("user_c6Mi1EpilrNy92q2EY60u");
  },
  mounted() {
    this.$root.$on("toggle-drawer", () => {
      this.dialog = !this.dialog;
    });
  },
  computed: {
    ...mapGetters(["getCartByUser", "totalAmount", "user"]),
  },
  methods: {
    ...mapActions(["removeProductFromCart", "createRequest"]),
    async remove(id) {
      await this.removeProductFromCart(id);
    },
    sendEmail() {
      emailjs.send("service_2iak8pi", "template_x2isk1c", {}).then(
        (result) => {
          console.log("SUCCESS!", result.status, result.text);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
    },
    async sendRequest() {
      try {
        this.loading = true;
        if (this.getCartByUser.length == 0) {
          this.alert.visible = true;
          this.alert.text = "Error al realizar el pedido";
          this.close();
          return;
        }
        const request = {
          note: this.text,
          userId: this.user.uid,
          idRequest:
            "SKU" + Math.floor(Math.random() * (1000 - 1000 + 1000) + 1000),
          products: this.getCartByUser,
        };
        var result = await this.createRequest(request);
        if (result == 500) {
          this.alert.visible = true;
          this.alert.text = "Error al realizar el pedido";
          this.close();
          return;
        }
        await this.getCartByUser.map(async (element) => {
          await this.remove(element.id);
        });
        this.note = "";
        this.alert.visible = true;
        this.alert.text = "Se ha realizado el pedido";

        this.sendEmail();

        this.loading = false;
        this.close();
      } catch (e) {
        this.alert.visible = true;
        this.alert.text = "Error al realizar el pedido";
        this.close();
      }
    },
    close() {
      setTimeout(() => {
        this.alert.visible = false;
        this.dialog = false;
      }, 1000);
    },
  },
};
</script>
<style lang="css">
.main {
  overflow: hidden !important;
  max-height: 100%;
}
.listStyle {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 350px;
}
.alertShow {
  width: 40% !important;
}
@media handheld, only screen and (max-width: 462px) {
  .alertShow {
    width: 100% !important;
  }
}
.textArea {
  max-height: 4em;
}
.carTitle {
  color: white !important;
  background-color: black;
}
.itemTitle {
  color: black;
  padding-bottom: 10px;
}
.toLeft {
  float: left;
}
.totalPrice {
  text-align: right;
  font-size: 0.8em;
}
.center {
  display: block;
  margin: auto;
}
.row1 {
  color: gray;
  font-size: 1em;
  padding-bottom: 15px;
  padding-top: 10px;
  text-align: left !important;
}
.row2 {
  margin: 15px;
  font-size: 1em;
  color: black;
  text-align: center;
}
.itemInfo {
  vertical-align: middle;
}
.vertical-center {
  padding-top: 10%;
  padding-left: 2%;
  padding-right: 2%;
}
.buttonStyle {
  color: red;
}
.message {
  font-size: 2em;
  text-align: center;
}
</style>
