<template>
  <v-container>
    <v-card elevation="0" max-width="700">
      <v-form v-model="valid">
        <v-text-field
          v-model="form.name"
          :rules="rules.nameRules"
          label="NOMBRE"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.lastname"
          :rules="rules.nameRules"
          label="APELLIDO"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.phone"
          :rules="rules.phoneRules"
          label="TELEFONO"
          v-mask="'DDD-DDD-DDDD'"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.email"
          :rules="rules.emailRules"
          label="CORREO"
          required
        ></v-text-field>
        <v-select
          :items="selectorReason"
          label="RAZÓN DE LA SOLICITUD"
          v-model="form.reason"
          :rules="rules.selectRules"
        ></v-select>
        <v-textarea
          solo
          name="input-7-4"
          label="MENSAJE"
          v-model="form.message"
        ></v-textarea>
        <v-btn
          class="button"
          block
          sm4
          tile
          :loading="loading"
          color="black"
          dark
          @click="sendEmail()"
          >Enviar</v-btn
        >
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import * as emailjs from "emailjs-com";
export default {
  data() {
    return {
      valid: true,
      selectorReason: [
        "Información comercial",
        "Información sobre pedido",
        "Quiero ser cliente",
        "Reclamacion",
        "Otra razón",
      ],
      loading: false,
      form: {
        name: "",
        lastname: "",
        phone: "",
        reason: "",
        email: "",
        message: "",
      },
      rules: {
        nameRules: [
          (v) => !!v || "El campo es requerido",
          (v) => (v && v.length >= 2) || "",
        ],
        selectRules: [(v) => !!v || "El campo es requerido"],
        messageRules: [
          (v) => !!v || "El campo es requerido",
          (v) => (v && v.length <= 10) || "Debe ser mas largo",
        ],
        phoneRules: [
          (v) => !!v || "El campo es requerido",
          (v) => (v && v.length <= 12) || "Debe ser un telefono",
        ],
        emailRules: [
          (v) => !!v || "El campo es requerido",
          (v) => /.+@.+\..+/.test(v) || "El correo debe ser valido",
        ],
      },
    };
  },
  created() {
    emailjs.init("user_c6Mi1EpilrNy92q2EY60u");
  },
  methods: {
    sendEmail() {
      if (this.valid) {
        this.loading = true;
        emailjs
          .send("service_2iak8pi", "template_w6e8rtg", {
            name: this.form.name,
            tell: this.form.phone,
            reason: this.form.reason,
            message: this.form.message,
            email: this.form.email,
          })
          .then(() => {
            this.$emit("show-snackbar", "Se ha enviado.");

            this.loading = false;
            this.form = {
              name: "",
              lastname: "",
              phone: "",
              reason: "",
              email: "",
              message: "",
            };
          })

          .catch(() => {
            this.$emit("show-snackbar", "Error intente mas tarde.");
          });
        this.loading = false;
      }
    },
  },
};
</script>
