<template>
  <v-container>
    <v-row justify="center" class="mb-5">
      <v-card elevation="0" width="350" max-width="400">
        <v-card-title class="title text-center"
          >Crear cuenta de socio</v-card-title
        >
        <v-form v-model="valid">
          <v-text-field
            v-model="nombreTienda"
            :rules="nameRules"
            label="Nombre de la tienda"
            required
          ></v-text-field>

          <v-text-field
            v-model="nombreEncargado"
            :rules="nameRules"
            label="Nombre del encargado"
            required
          ></v-text-field>

          <v-text-field
            v-model="telefono"
            label="Telefono"
            v-mask="'DDD-DDD-DDDD'"
            :rules="nameRules"
            required
          ></v-text-field>

          <v-select
            v-model="provinciaSeleccionada"
            :items="provincias"
            item-text="provincia"
            label="Provincia"
            required
          ></v-select>

          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[passwordRules]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          >
          </v-text-field>

          <v-text-field
            v-model="confirmPassword"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[passwordRules]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            hint="At least 8 characters"
            counter
            @click:append="show2 = !show2"
          >
          </v-text-field>
          <v-btn
            class="button"
            block
            @click="registerUser()"
            sm4
            :loading="loading"
            tile
            color="black"
            dark
            >Registrarse</v-btn
          >
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      show1: false,
      show2: false,
      valid: false,
      loading: false,
      passwordRules: [(value) => !!value || "El campo es requerido"],
      direccionRules: [
        (value) => !!value || "El campo es requerido",
        (v) => (v && v.length >= 8) || "Ninimo 10 caracteres",
      ],
      nameRules: [(v) => !!v || "El campo es requerido"],
      phoneRules: [
        (v) => !!v || "El campo es requerido",
        (v) => (v && v.length <= 12) || "Debe tener al menos 10 caracteres",
      ],
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe ser valido",
      ],
      email: "",
      nombreTienda: "",
      nombreEncargado: "",
      telefono: "",
      provinciaSeleccionada: "",
      direccion: "",
      password: "",
      confirmPassword: "",
      provincias: [
        {
          provincia_id: 1,
          provincia: "Distrito Nacional",
        },
        {
          provincia_id: 21,
          provincia: "San Pedro de Macorís",
        },
        {
          provincia_id: 22,
          provincia: "La Romana",
        },
        {
          provincia_id: 23,
          provincia: "La Altagracia",
        },
        {
          provincia_id: 24,
          provincia: "El Seibo",
        },
        {
          provincia_id: 25,
          provincia: "Hato Mayor",
        },
        {
          provincia_id: 31,
          provincia: "Duarte",
        },
        {
          provincia_id: 32,
          provincia: "Samaná",
        },
        {
          provincia_id: 33,
          provincia: "Maria Trinidad Sánchez",
        },
        {
          provincia_id: 34,
          provincia: "Salcedo",
        },
        {
          provincia_id: 41,
          provincia: "La Vega",
        },
        {
          provincia_id: 42,
          provincia: "Monseñor Nouel",
        },
        {
          provincia_id: 43,
          provincia: "Sánchez Ramirez",
        },
        {
          provincia_id: 51,
          provincia: "Santiago",
        },
        {
          provincia_id: 56,
          provincia: "Espaillat",
        },
        {
          provincia_id: 57,
          provincia: "Puerto Plata",
        },
        {
          provincia_id: 61,
          provincia: "Valverde",
        },
        {
          provincia_id: 62,
          provincia: "Monte Cristi",
        },
        {
          provincia_id: 63,
          provincia: "Dajabónn",
        },
        {
          provincia_id: 64,
          provincia: "Santiago Rodríguez",
        },
        {
          provincia_id: 71,
          provincia: "Azua",
        },
        {
          provincia_id: 72,
          provincia: "San Juan de la Maguana",
        },
        {
          provincia_id: 73,
          provincia: "Elías Piña",
        },
        {
          provincia_id: 81,
          provincia: "Barahona",
        },
        {
          provincia_id: 82,
          provincia: "Bahoruco",
        },
        {
          provincia_id: 83,
          provincia: "Independencia",
        },
        {
          provincia_id: 84,
          provincia: "Perdenales",
        },
        {
          provincia_id: 91,
          provincia: "San Cristóbal",
        },
        {
          provincia_id: 92,
          provincia: "Monte Plata",
        },
        {
          provincia_id: 93,
          provincia: "San José de Ocoa",
        },
        {
          provincia_id: 94,
          provincia: "Peravia",
        },
      ],
      latitud: null,
      longitud: null,
    };
  },
  created() {
    this.locatorButtonPressed();
  },
  methods: {
    ...mapActions(["createUser", "signupUserInFirebase"]),
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitud = position.coords.latitude;
          this.longitud = position.coords.longitude;
        },
        (error) => {
          this.latitud = 0;
          this.longitud = 0;
          console.log(error.message);
        }
      );
    },
    async registerUser() {
      this.loading = true;
      if (!this.valid) {
        this.$emit("show-snackbar", "No puede dejar ningun campo vacio");
        this.loading = false;
        return;
      }

      if (this.confirmPassword != this.password) {
        this.$emit("show-snackbar", "Confirme la Contraseña");
        this.loading = false;
        return;
      }

      const user = {
        uid: "",
        nameStore: this.nombreTienda,
        nameInCharge: this.nombreEncargado,
        tell: this.telefono,
        selectCity: this.provinciaSeleccionada,
        address: this.direccion,
        email: this.email,
        password: this.password,
        lot: this.latitud,
        long: this.longitud,
      };

      var uid = await this.signupUserInFirebase(user);
      user.uid = uid;
      var result = await this.createUser(user);

      if (!result) {
        this.$emit("show-snackbar", "No se ha podido crear el producto");
        this.loading = false;
        return;
      }
      this.$emit("show-snackbar", "Se ha creado el Usuario");

      this.loading = false;
    },
  },
};
</script>
<style scoped>
.title {
  font-size: 30px !important;
  font-weight: bold !important;
  font-family: "arial";
  padding-left: 0%;
  margin-left: 0%;
}
.text {
  text-decoration: none;
  color: black;
}
.button {
  margin-top: 20px;
}
</style>
