<template>
  <v-container>
    <v-row class="mt-5" dense>
      <v-col cols="12">
        <p class="profileTitle">Bienvenido a tu perfil de socio comercial</p>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3">
        <p class="perfilTitle">Mi perfil</p>
        <div v-for="(item, index) in info" :key="index">
          <h4 class="itemTitle">{{ item.name }}</h4>
          <p>{{ item.value }}</p>
        </div>

        <v-row dense>
          <h5>Ajustar margen de ganancia</h5>
          <v-col cols="3" lg="4" md="4" sm="4">
            <h1>{{ counterComputed }}%</h1>
          </v-col>
          <v-col cols="3" lg="4" md="4" sm="4">
            <v-btn fab dark small color="black" @click="counterLess()">
              <v-icon dark>
                mdi-minus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col class="4" lg="4" md="4" sm="4">
            <v-btn fab dark small color="black" @click="counterPlus()">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              outlined
              color="blue"
              @click="savePercent()"
              class="mb-3 mt-5 "
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>

        <v-btn
          class="mt-5 mb-5"
          @click="logout()"
          outlined
          color="red"
          small
          width="200"
          >Cerrar Sesión</v-btn
        >
      </v-col>
      <v-col cols="12" lg="9" md="9" sm="9">
        <p class="ml-1 title text-bold">Mis Pedidos</p>
        <v-data-table
          :loading="load"
          loading-text="Cargando... por favor espere"
          :headers="headers"
          :items="requests"
          no-data-text="No hay datos disponible."
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.created="{ item }">
            <div>
              {{ item.created | date }}
            </div>
          </template>
          <template v-slot:item.id="{ item }">
            <button
                @click="showProducts(item)"
               
                class="btn btn-outline-primary text-width-bold contenedor"
              >
                <span>Ver pedido</span>
              </button>
          
          </template>
          <template v-slot:item.status="{ item }">
            <v-btn
              text
              small
              :color="item.status != 'Completado' ? 'orange' : 'green'"
            >
              <v-icon left color="orange" v-if="item.status == 'Proceso'"
                >query_builder</v-icon
              >
              <v-icon left color="orange" v-if="item.status == 'En camino'"
                >moped</v-icon
              >
              <v-icon left v-if="item.status == 'Completado'">
                done
              </v-icon>
              {{ item.status }}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-card-title>Productos del pedido</v-card-title>
        <v-checkbox
          class="subTitle ml-6"
          v-model="updateModel"
          :disabled="itemSelected.status == 'En camino' ? false : true"
          label="Marcar como recibido"
          aria-setsize="40"
        ></v-checkbox>
        <v-data-table
          loading-text="Cargando... por favor espere"
          :headers="headersProduct"
          :items="products"
          no-data-text="No hay datos disponible."
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.img="{ item }">
            <div class="p-2">
              <v-img
                :src="item.img"
                :alt="item.name"
                width="50px"
                height="50px"
              ></v-img>
            </div>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>

          <v-btn color="green darken-1" :loading="loading" text @click="update">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDateNew } from "../filters";
export default {
  filters: {
    date(value) {
      return formatDateNew(value);
    },
  },
  name: "Profile",

  components: {},
  data() {
    return {
      counter: 0,
      info: [],
      load: true,
      dialog: false,
      loading: false,
      products: [],
      headersProduct: [
        {
          text: "Imagen",
          align: "start",
          sortable: false,
          value: "img",
        },

        { text: "Artículo", value: "name" },
        { text: "Tamaño", value: "currentSize" },
        { text: "Sexo", value: "category" },
        { text: "Categoria", value: "subCategory" },
        { text: "Sub Categoria", value: "itemSubCategory" },
        { text: "Cantidad", value: "quantity" },
      ],
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "idRequest",
        },

        { text: "Nota", value: "note" },
        { text: "Estado", value: "status", align: "center" },
        { text: "Fecha", value: "created" },
        { text: "Ver", value: "id" },
      ],
      itemSelected: [],
      updateModel: false,
    };
  },
  mounted() {
    this.loadRequests();
  },
  beforeMount() {
    this.loadUser();
    setTimeout(() => {
      this.$emit(
        "showAlert",
        "Bienvenido, Un gusto verte por aqui otra vez",
        false
      );
    }, 1000);
  },
  computed: {
    ...mapGetters(["user", "requestsByUser"]),
    counterComputed() {
      return this.counter;
    },
    requests() {
      return this.requestsByUser.sort(function(a, b) {
        return new Date(b.created.seconds) - new Date(a.created.seconds);
      });
    },
  },
  methods: {
    ...mapActions([
      "logoutUserFromFirebase",
      "getRequestsByUser",
      "updatedUser",
      "updateRequest",
    ]),
    counterPlus() {
      this.counter++;
    },
    counterLess() {
      if (this.counter == 0) {
        return;
      }
      this.counter--;
    },
    async savePercent() {
      var user = this.user;
      user.percent = this.counter;
      try {
        await this.updatedUser(user);
        this.$emit(
          "show-snackbar",
          "Se ha actualizado el precio de los productos"
        );
      } catch (e) {}
    },
    async update() {
      if (!this.updateModel) {
        this.dialog = false;
        return;
      }
      this.loading = true;
      this.itemSelected.status = "Completado";
      await this.updateRequest(this.itemSelected);
      this.loadRequests();
      this.dialog = false;
      this.loading = false;
      this.updateModel = false;
      this.$emit("show-snackbar", "Se ha actualizado el pedido");
    },
    loadUser() {
      if (this.user.percent != undefined) {
        this.counter = this.user.percent;
      }

      this.info = [
        {
          name: "Tienda",
          value: this.user.nameStore,
        },
        {
          name: "Encargado",
          value: this.user.nameInCharge,
        },
        {
          name: "Teléfono",
          value: this.user.tell,
        },
        {
          name: "Provincia",
          value: this.user.selectCity,
        },
      ];
    },
    async loadRequests() {
      await this.getRequestsByUser(this.user.uid);
      this.load = false;
    },
    showProducts(item) {
      this.dialog = true;
      this.products = item.products;
      this.itemSelected = item;
    },

    async logout() {
      this.$emit("showOverlay", true);
      await this.logoutUserFromFirebase();
      this.$emit("showOverlay", false);
    },
  },
};
</script>
<style lang="css">
.rowContainer {
  justify-content: flex-end !important;
  margin-right: 20px;
}
@media handheld, only screen and (max-width: 463px) {
  .rowContainer {
    justify-content: center !important;
  }
  .counter {
    width: 120px;
    min-width: 120px;
  }
}
.perfilTitle {
  font-size: 1.5em;
  color: gray;
  vertical-align: middle;
}
.itemTitle {
  font-size: 1em;
  color: black;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.itemInfo {
  font-size: 1em;
  color: gray;
}

.main {
  margin: 30px;
}
.profileTitle {
  color: black;
  font-size: 2em;
  text-align: center;
}
.btnSave {
  border-radius: 20px;
  width: 140px;
}
.buttons {
  width: 140px;
  min-width: 140px;
}
.counter {
  width: 100px;
  min-width: 100px;
}
</style>
