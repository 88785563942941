<template>
  <div>
    <div class="d-flex flex-row-reverse flexbox">
      <v-card
        min-width="350"
        height="100%"
        class="filterStyle pa-2 pb-10"
        v-if="showFilter"
      >
        <button @click="filterVisibility" icon>
          <v-icon color="black">close</v-icon>
        </button>
        <button class="float-right">Filtro</button>
        <div v-for="(item, index) in filterAttirbutes" :key="index">
          <p class="selected">{{ item.name }}</p>
          <div v-for="(subitem, index) in item.items" :key="index">
            <v-row
              @click="itemSelected(subitem)"
              v-if="item.show || index < 3"
              :class="itemSelectedStyle(subitem.selected)"
            >
              <v-col v-if="haveCode(subitem)" cols="12" md="2">
                <v-card
                  width="30"
                  height="30"
                  rounded
                  :color="subitem.code"
                ></v-card>
              </v-col>
              <v-col>
                {{ subitem.value }}
              </v-col>
              <v-col md="4">
                <v-icon v-if="subitem.selected" left size="20">
                  done
                </v-icon>
              </v-col>
            </v-row>
          </div>
          <button class="mb-5" v-if="item.show" @click="item.show = !item.show">
            Mostrar Menos
            <v-icon size="20">keyboard_arrow_up</v-icon>
          </button>
          <button
            class="mb-5"
            v-if="!item.show"
            @click="item.show = !item.show"
          >
            Mostrar Más
            <v-icon size="20">expand_more</v-icon>
          </button>
        </div>
        <br />
        <br />
      </v-card>
    </div>
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12" lg="2" md="3" sm="5">
          <v-text-field
            v-model="search"
            class="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="4"
          md="2"
          sm="3"
          lg="1"
          v-for="(data, index) in itemSubCategories"
          :key="index"
        >
          <div class="flat-button is-active" text @click="filterProduct(data)">
            {{ data }}
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <span class="divisor"> Articulos: {{ productFilter.length }} </span>
          <span class="divisor">|</span>
          <button @click="filterVisibility">Filtros {{ countFilters }}</button>
        </v-col>
      </v-row>
      <v-container>
        <h1 class="message" v-if="getProductsComputed.length === 0">
          Sin resultados para esta Búsqueda
        </h1>
        <v-row>
          <v-col
            cols="12"
            md="6"
            sm="6"
            lg="4"
            v-for="(item, index) in getProductsComputed"
            :key="index"
          >
            <v-card
              @click="goToDetailProduct(item)"
              elevation="0"
              class="mx-auto mt-5"
              max-width="390"
              height="350"
            >
              <v-img
                :src="item.img"
                aspect-ratio="1"
                :lazy-src="item.img"
                height="300px"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-card-title>
                {{ item.name }}
              </v-card-title>

              <v-card-subtitle>
                RD${{
                  item.pricePorcent == null
                    ? item.price
                    : sumPrice(item.price, item.pricePorcent)
                }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      currentSubCategory: "",
      filters: [],
      showFilter: false,
      filterVisible: false,
      filterAttirbutes: [
        {
          name: "Tallas",
          show: false,
          items: [
            { value: "XS", selected: false },
            { value: "S", selected: false },
            { value: "M", selected: false },
            { value: "L", selected: false },
            { value: "XL", selected: false },
            { value: "XXL", selected: false },
            { value: "XS-S", selected: false },
            { value: "S-M", selected: false },
            { value: "M-L", selected: false },
          ],
        },
        {
          name: "Colores",
          show: false,
          items: [
            {
              value: "AMARILLO",
              code: "#ffff00",
              selected: false,
              visible: true,
            },
            {
              value: "AZUL",
              code: "#0000ff",
              selected: false,
              visible: true,
            },
            {
              value: "BEIGE",
              code: "#f5f5dc",
              selected: false,
              visible: true,
            },
            {
              value: "BLANCO",
              code: "#FFFFFF",
              selected: false,
              visible: true,
            },
            {
              value: "GRIS",
              code: "#9b9b9b",
              selected: false,
              visible: true,
            },
            {
              value: "CAQUI",
              code: "#e0d8b0",
              selected: false,
              visible: true,
            },
            {
              value: "MARRÓN",
              code: "#804000",
              selected: false,
              visible: true,
            },
            {
              value: "METALIZADO",
              code: "#e3e4e5",
              selected: false,
              visible: true,
            },
            {
              value: "MORADO",
              code: "#572364",
              selected: false,
              visible: true,
            },
            {
              value: "NARANJA",
              code: "#ff8000",
              selected: false,
              visible: true,
            },
            {
              value: "NEGRO",
              code: "#000000",
              selected: false,
              visible: true,
            },
            {
              value: "ROJO",
              code: "#FF0000",
              selected: false,
              visible: true,
            },
            {
              value: "ROSA",
              code: "#ff0080",
              selected: false,
              visible: true,
            },
            {
              value: "VERDE",
              code: "#008f39",
              selected: false,
              visible: true,
            },
            {
              value: "CRUDOS",
              code: null,
              selected: false,
              visible: true,
            },
            {
              value: "ESTAMPADO",
              code: null,
              selected: false,
              visible: true,
            },
            {
              value: "OTROS",
              code: null,
              selected: false,
              visible: true,
            },
          ],
        },
      ],
      params: this.$route.params.filter,
      itemSubCategories: [],
      productFilter: [],
    };
  },
  mounted() {
    this.loadProduct();

    this.filter(this.params);
  },
  computed: {
    ...mapGetters(["productsWithPorcent"]),
    getSearch() {
      return this.search;
    },
    getProductsComputed() {
      if (this.getSearch === "") {
        return this.productFilter;
      } else {
        return this.productFilter.filter((x) =>
          x.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
    },
    countFilters() {
      return this.filters.length;
    },
  },
  watch: {
    $route(to, from) {
      if (this.params === this.$route.params.filter) return;
      this.params = this.$route.params.filter;
      this.filter(this.params);
    },
  },
  methods: {
    ...mapActions(["getProducts", "searchFilter"]),
    async loadProduct() {
      await this.getProducts();
    },
    sumPrice(price, percent) {
      return parseInt(price) + parseInt(percent);
    },
    filterByOpctions(item) {
      var productByOptions = this.productFilter;
    },
    goToDetailProduct(product) {
      this.$router.push("/productDetail/" + product.id).catch((err) => {});
    },
    itemSelectedStyle(value) {
      return {
        selectedSize: !value,
        selected: value,
      };
    },
    haveCode(item) {
      if (item.code == undefined) {
        return false;
      } else {
        return true;
      }
    },
    itemSelected(item) {
      item.selected = !item.selected;
      this.filterProduct(this.currentSubCategory);
      //this.filterByOpctions(item);
    },
    filterSelected(params) {
      var filters = [];
      params.forEach((element) => {
        element.items.forEach((item) => {
          if (item.selected) {
            filters.push(item.value);
          }
        });
      });
      this.filters = filters;
      var itemsFilters = [];
      if (filters.length > 0) {
        this.productFilter.forEach((element) => {
          var result = false;
          filters.forEach((item) => {
            if (
              element.sizes
                .map((x) => x.value.toUpperCase())
                .includes(item.toUpperCase()) ||
              element.color.includes(item.toUpperCase())
            ) {
              result = true;
            }
          });
          if (result) {
            itemsFilters.push(element);
          }
        });
        this.productFilter = itemsFilters;
      }
    },
    mouseIn: function() {
      this.showFilter = true;
    },
    mouseOut: function() {
      this.showFilter = false;
    },
    filterVisibility() {
      this.showFilter = !this.showFilter;
    },
    filter(params) {
      params = JSON.parse(params);

      this.currentSubCategory = params.categorySelect;
      if (params.items.length != 0) {
        this.itemSubCategories = params.items;
        this.itemSubCategories.push("TODAS");
        this.itemSubCategories.reverse();
      } else {
        this.itemSubCategories = [];
      }

      if (params) {
        if (params.items.length == 0) {
          var items = this.productsWithPorcent.filter(
            (p) =>
              p.category.toLowerCase() == params.category.toLowerCase() &&
              p.subCategory.toLowerCase() == params.categorySelect.toLowerCase()
          );
        } else {
          var items = this.productsWithPorcent.filter(
            (p) =>
              p.category.toLowerCase() == params.category.toLowerCase() &&
              p.itemSubCategory.toLowerCase() ==
                params.categorySelect.toLowerCase() &&
              p.subCategory.toLowerCase() == params.subCategory.toLowerCase()
          );
        }

        this.productFilter = items;
      } else {
        this.productFilter = this.productsWithPorcent;
      }
      this.filterSelected(this.filterAttirbutes);
    },
    filterProduct(item) {
      this.currentSubCategory = item;
      var parameters = JSON.parse(this.params);
      if (item == "TODAS") {
        this.productFilter = this.productsWithPorcent.filter(
          (p) =>
            p.category.toLowerCase() == parameters.category.toLowerCase() &&
            p.subCategory.toLowerCase() == parameters.subCategory.toLowerCase()
        );

        this.filterSelected(this.filterAttirbutes);
        return;
      }
      this.productFilter = this.productsWithPorcent.filter(
        (p) =>
          p.category.toLowerCase() == parameters.category.toLowerCase() &&
          p.itemSubCategory.toLowerCase() == item.toLowerCase() &&
          p.subCategory.toLowerCase() == parameters.subCategory.toLowerCase()
      );

      this.filterSelected(this.filterAttirbutes);
    },
  },
};
</script>
<style lang="css">
.btn:active {
  outline: 10 !important;
  border: black !important;
  color: #ffb2b2;
}
.filterStyle {
  position: fixed !important;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto !important;
}
/* .flat-button {
  font-weight: bold;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
.flat-button:hover {
  opacity: 1;
  -webkit-transform: translate(0, 5px);
  transform: translate(0, 5px);
  text-decoration: underline;
}
.flat-button:active {
  text-decoration: underline;
} */
.flat-button,
.flat-button:link,
.flat-button:visited,
.flat-button:active {
  display: block;
  position: relative;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 15px !important;
  text-decoration: none;
  outline: none;
  color: black;
}
.flat-button::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.flat-button:hover::before,
.flat-button:focus::before {
  opacity: 1;
  -webkit-transform: translate(0, 5px);
  transform: translate(0, 5px);
}
.flat-button:active {
  opacity: 1;
  -webkit-transform: translate(0, 5px);
  transform: translate(0, 5px);
}
.filterContainer {
  text-align: right;
}
.divisor {
  margin-right: 5px;
}
.selectedSize {
  cursor: pointer;
  color: black;
}
.selected {
  font-weight: bold;
  color: black;
  cursor: pointer;
}
.message {
  font-size: 2em;
  text-align: center;
  margin-top: 30px;
}
.search {
  max-width: 200px;
}
</style>
