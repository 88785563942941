<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="6" md="6" lg="4">
        <v-card elevation="0">
          <v-card-title>INICIA SESIÓN</v-card-title>

          <v-form v-model="valid" ref="form">
            <v-row justify="center">
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Contraseña"
                  v-model="password"
                  min="6"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  @click:append="show2 = !show2"
                  :type="show2 ? 'text' : 'password'"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  class="button"
                  block
                  :loading="loading"
                  @click="singIn()"
                  sm4
                  tile
                  color="black"
                  dark
                  >Iniciar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="5">
        <v-card elevation="0">
          <v-card-title>REGÍSTRATE COMO SOCIO</v-card-title>

          <p class="text-justify font-weight-medium  ">
            Si todavía no tienes una cuenta de usuario en Sadaii.com utiliza
            esta opción para acceder al formulario de registro.<br /><br />
            Te solicitaremos la información imprescindible para agilizar el
            proceso de compra.
          </p>

          <v-btn class="button" block sm4 tile color="black" dark to="/register"
            >Crear Cuenta</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "../../router/index";
export default {
  name: "login",
  data() {
    return {
      valid: false,
      e1: false,
      password: "",
      show2: false,
      loading: false,
      email: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  beforeMount() {
    if (this.user) {
      this.$router.push("/profile");
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["signinUserInFirebase", "getUserByEmail"]),

    clear() {
      this.$refs.form.reset();
    },
    async singIn() {
      if (!this.valid) {
        this.$emit("show-snackbar", "No puede dejar ningun campo vacio");
        return;
      }
      this.loading = true;
      const user = {
        email: this.email,
        password: this.password,
      };

      this.signinUserInFirebase({
        user,
        alternate: false,
      })
        .then(async (user) => {
          this.$emit(
            "showAlert",
            "Bienvenido, Un gusto verte por aqui otra vez",
            true
          );
          await this.getUserByEmail(user.email);
        })
        .catch((err) => {
          this.$emit("show-snackbar", "Correo o contraseña son invalido.");
        });
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.text {
  text-decoration: none;
  color: black;
}
.button {
  margin-top: 20px;
}
</style>
