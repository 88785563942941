<template>
  <v-container>
    <v-row>
      <v-col class="12" lg="6" md="6" sm="6">
        <p class="imagesContainer">
          <v-img
            v-for="(item, index) in product.imgs"
            :key="index"
            :src="item"
            aspect-ratio="1"
            :lazy-src="item"
          >
          </v-img>
        </p>
      </v-col>
      <v-col cols="12" lg="6" sm="6" md="6" class="hidden-sm-and-down">
        <v-card elevation="0" class="infoContainer">
          <div>
            <p class="title">{{ product.name }}</p>
          </div>

          <div class="colorText">{{ product.color }}</div>

          <div class="alingLeft actualPrice">
            RD${{
              product.pricePorcent == null
                ? product.price
                : sumPrice(product.price, product.pricePorcent)
            }}
          </div>

          <br />

          <p>{{ product.description }}</p>

          <v-divider></v-divider>
          <v-row>
            <v-col cols="4">
              <div v-for="(item, index) in product.sizes" :key="index">
                <v-row
                  v-if="item.visible"
                  @click="select(item, product.sizes)"
                  :class="avalible(item)"
                >
                  <v-col>
                    {{ item.value }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="8">
              <div class="scrolled">
                <div v-for="(color, i) in product.colors" :key="i">
                  <v-row
                    v-if="color.visible"
                    @click="selectColor(color, product.colors)"
                    class="selectedSize"
                  >
                    <v-col cols="4" md="4" lg="4" sm="4">
                      <v-card
                        width="30"
                        height="30"
                        rounded
                        :color="color.code"
                      ></v-card>
                    </v-col>
                    <v-col>
                      {{ color.color }}
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row justify="center">
            <v-col cols="3" lg="2" sm="2" md="2">
              <v-btn @click="decrement" class=" primary">
                -
              </v-btn>
            </v-col>
            <v-col cols="4" lg="3" sm="3" md="3" class="">
              <p class="text-center title">{{ quantity }}</p>
            </v-col>
            <v-col cols="3" lg="2" sm="2" md="2">
              <v-btn @click="increment" class=" primary">
                +
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                @click="handlerAddProductToCart(product)"
                class="button"
                block
                sm4
                tile
                :loading="loading"
                :disabled="loading"
                color="black"
                dark
                >Añadir al carrito</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12"> </v-col>
    </v-row>

    <swipeable-bottom-sheet
      ref="swipeableBottom"
      class="hidden-md-and-up"
      @halfY="0.9"
    >
      <v-card elevation="0" class="infoContainer">
        <div>
          <p class="title">{{ product.name }}</p>
        </div>

        <div class="colorText">{{ product.color }}</div>

        <div class="alingLeft actualPrice">
          RD${{
            product.pricePorcent == null
              ? product.price
              : sumPrice(product.price, product.pricePorcent)
          }}
        </div>

        <br />

        <p>{{ product.description }}</p>

        <v-divider></v-divider>
        <v-row>
          <v-col cols="4">
            <div v-for="(item, index) in product.sizes" :key="index">
              <v-row
                v-if="item.visible"
                @click="select(item, product.sizes)"
                :class="avalible(item)"
              >
                <v-col>
                  {{ item.value }}
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="8">
            <div class="scrolled">
              <div v-for="(color, i) in product.colors" :key="i">
                <v-row
                  v-if="color.visible"
                  @click="selectColor(color, product.colors)"
                  class="selectedSize"
                >
                  <v-col cols="4" md="2" lg="2" sm="2">
                    <v-card
                      width="30"
                      height="30"
                      rounded
                      :color="color.code"
                    ></v-card>
                  </v-col>
                  <v-col>
                    {{ color.color }}
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row justify="center">
          <v-col cols="3" lg="2" sm="2" md="2">
            <v-btn @click="decrement" class=" primary">
              -
            </v-btn>
          </v-col>
          <v-col cols="4" lg="3" sm="3" md="3" class="">
            <p class="text-center title">{{ quantity }}</p>
          </v-col>
          <v-col cols="3" lg="2" sm="2" md="2">
            <v-btn @click="increment" class=" primary">
              +
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              @click="handlerAddProductToCart(product)"
              class="button"
              block
              sm4
              tile
              :loading="loading"
              :disabled="loading"
              color="black"
              dark
              >Añadir al carrito</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </swipeable-bottom-sheet>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { SwipeableBottomSheet } from "vue-swipeable-bottom-sheet";
export default {
  name: "ProductDetail",
  components: {
    SwipeableBottomSheet,
  },
  data() {
    return {
      loading: false,
      size: null,
      color: null,
      params: this.$route.params.id,
      product: {},
    };
  },
  mounted() {
    this.loadProduct();
    this.open();
  },
  created() {},
  computed: {
    ...mapGetters(["user", "productsWithPorcent"]),
    quantity() {
      return this.product.quantity;
    },
  },
  methods: {
    ...mapActions(["addProductToCart", "getProducts"]),
    open() {
      this.$refs.swipeableBottom.setState("half");
    },
    sumPrice(price, percent) {
      return parseInt(price) + parseInt(percent);
    },
    async loadProduct() {
      this.getProducts();
      this.product = this.productsWithPorcent.find((p) => p.id == this.params);
    },
    increment() {
      if (this.product.quantity === 99) return;
      this.product.quantity++;
    },
    decrement() {
      if (this.product.quantity === 0) return;
      this.product.quantity--;
    },
    handlerAddProductToCart(product) {
      this.loading = true;
      if (this.user == null) {
        this.loading = false;
        this.$emit(
          "show-snackbar",
          "Tienes que iniciar sesión para añadir al carrito"
        );
        return;
      }

      if (this.size == null && this.product.sizes.length != 1) {
        this.loading = false;
        this.$emit("show-snackbar", "Tiene que seleccionar el tamaño");
        return;
      }
      if (this.color == null && this.product.colors.length != 1) {
        this.loading = false;
        this.$emit("show-snackbar", "Tiene que seleccionar el color");
        return;
      }
      if (this.product.quantity == 0) {
        this.loading = false;
        this.$emit("show-snackbar", "Tiene que elegir una cantidad");
        return;
      }

      product.currentSize =
        this.size == null ? this.product.sizes[0].value : this.size;
      product.color =
        this.color == null ? this.product.colors[0].value : this.color;
      product.userId = this.user.uid;

      this.addProductToCart(product);
      this.$emit("show-snackbar", "Se ha añadido el articulo al carro");
      this.loading = false;
    },
    selectColor(item, collection) {
      if (item.selected) {
        this.color = null;
        collection.forEach((element) => {
          if (element !== item) {
            element.visible = true;
          } else {
            item.selected = false;
          }
        });
      } else {
        collection.forEach((element) => {
          if (element !== item) {
            element.visible = false;
            item.selected = true;

            this.color = item.color;
          }
        });
      }
    },
    select(item, collection) {
      if (item.avalible !== undefined && !item.avalible) return;

      if (item.selected) {
        this.size = null;
        collection.forEach((element) => {
          if (element !== item) {
            element.visible = true;
          } else {
            item.selected = false;
          }
        });
      } else {
        collection.forEach((element) => {
          if (element !== item) {
            element.visible = false;
            item.selected = true;

            this.size = item.value;
          }
        });
      }
    },
    avalible(item) {
      return {
        selectedSize: item.avalible,
        unvalible: !item.avalible,
        selected: item.selected,
      };
    },
  },
};
</script>
<style lang="css">
.infoContainer {
  position: fixed;
  margin: 4%;
  padding-bottom: 25px;
}

@media handheld, only screen and (max-width: 1262px) {
  .infoContainer {
    position: absolute;
  }
}

.textLink {
  text-decoration: none;
  vertical-align: middle;
  text-align: right;
  color: black !important;
  font-size: 1em;
}
.selectedSize {
  cursor: pointer;
  color: black;
}
.selected {
  font-weight: bold;
  color: black;
}
.unvalible {
  color: gray;
}

.alingLeft {
  float: left;
  margin-right: 10px;
}
.colorText {
  margin-bottom: 10px;
}
.originalPrice {
  text-decoration-line: line-through;
  font-weight: bold;
  color: black;
}
.actualPrice {
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.onlyPrice {
  color: black;
}

.ofert {
  font-size: 0.7em;
  border: black;
  border-width: 2px;
}
.imagesContainer {
  overflow: hidden;
  height: 100%;
}
.counter {
  font-weight: bold;
  size: 1.5em;

  text-align: center;
}
.buttonCounter {
  width: 50px;
}

.scrolled {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 50px;
  max-height: 200px;
}
</style>
